import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import {Observable, of, switchMap, take} from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {

  constructor(
    private authService: AuthService
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.admin();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.admin();
  }


  private admin(): Observable<boolean> {
    return this.authService.user$.pipe(
      take(1),
      switchMap(user => {
        let admin: boolean = false;
        if (user) {
          const roles = user["https://family-tricks.com/roles"] as string[];
          if (roles && roles.length > 0) {
            admin = roles.some(role => role === 'FamilyTricksTeamMember');
          }
        }
        return of(admin);
      })
    );
  }
}
